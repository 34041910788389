<script setup lang="ts">
    import type { CtxProduct } from '@containex/portal-backend-api-client';
    import { computed } from 'vue';
    import { sortedProductImages } from '@/util/image-sorter';
    import Galleria from 'primevue/galleria';
    import { isMobile } from '@/util/breakpoints';

    const props = defineProps<{
        product: CtxProduct;
    }>();

    const thumbnailBreakpointXl = 7;
    const thumbnailBreakpointLg = 6;
    const imageCount = computed(() => (props.product.images != null ? props.product.images.length : 0));

    const images = computed(() => sortedProductImages(props.product.images ?? []));
    const showThumbnails = computed(() => !isMobile.value);
    const responsiveOptions = computed(() => [
        {
            breakpoint: '1210px',
            numVisible: Math.min(imageCount.value, thumbnailBreakpointXl),
        },
        {
            breakpoint: '1082px',
            numVisible: Math.min(imageCount.value, thumbnailBreakpointLg),
        },
    ]);
</script>
<template>
    <div class="galleria-container">
        <Galleria
            v-if="product.images != null && product.images.length > 0"
            :value="images"
            :num-visible="Math.min(product.images.length, 8)"
            :show-thumbnails="showThumbnails"
            :show-indicators="!showThumbnails"
            :change-item-on-indicator-hover="true"
            :responsive-options="responsiveOptions"
            :show-item-navigators="!showThumbnails"
        >
            <template #item="slotProps">
                <img :src="slotProps.item.url" :alt="product.title" class="galleria-item-image" />
            </template>
            <template #thumbnail="slotProps">
                <img :src="slotProps.item.url" :alt="product.title" class="thumbnail-image" />
            </template>
        </Galleria>
        <div v-else class="missing-image">
            <img src="/assets/images/containex.svg" alt="CONTAINEX" class="logo-placeholder" />
        </div>
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .galleria-container :deep(.p-galleria) {
        .p-galleria-thumbnail-container {
            background: main.$color-background-bluegray;
        }

        // only apply when there are less then 8 children so they are aligned left
        .p-galleria-thumbnail-items:not(:has(> :nth-child(8))) {
            .p-galleria-thumbnail-item {
                flex: none !important;
            }
        }

        .p-galleria-thumbnail-item-content {
            line-height: normal;
        }

        .p-galleria-thumbnail-prev-button.p-disabled,
        .p-galleria-thumbnail-next-button.p-disabled {
            color: transparent;
        }
    }

    .galleria-item-image {
        height: 260px;
        width: 100%;
        object-fit: contain;
    }

    @include main.for-breakpoint-lg {
        .galleria-item-image {
            height: 470px;
        }
    }

    @include main.for-breakpoint-md {
        .galleria-item-image {
            height: 400px;
        }
    }

    .p-galleria-indicator.p-highlight button {
        background: main.$color-primary-500;
    }

    .thumbnail-image {
        width: 80px;
        height: 60px;
        object-fit: cover;
        padding-left: main.$spacing-2;
        padding-right: main.$spacing-2;
    }

    .missing-image {
        height: 230px;
        display: flex;
        justify-content: center;
        text-align: center;

        .logo-placeholder {
            width: 100%;
            padding: main.$spacing-7;
            vertical-align: middle;
            background: main.$color-surface-100;
        }
    }

    @include main.for-breakpoint-md {
        .missing-image {
            height: 400px;

            .logo-placeholder {
                width: 75%;
                padding: main.$spacing-8;
            }
        }
    }
</style>
