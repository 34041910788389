<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import Button from 'primevue/button';
    import Card from 'primevue/card';
    import type { CtxProductVariant } from '@containex/portal-backend-api-client';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { computed, watch } from 'vue';
    import IconButtonWithOverlayPanel from '@/components/IconButtonWithOverlayPanel.vue';
    import ProductAvailability from '@/components/ProductAvailability.vue';
    import DepotLocationWithIcon from '@/components/DepotLocationWithIcon.vue';
    import PriceInformation from '@/components/PriceInformation.vue';
    import { Availability } from '@containex/portal-backend-dto';
    import { getAvailabilityForVariantBasedOnCart } from '@/util/variantAvailability';
    import { useCartAction, useCartQuery } from '@/composables/cart';

    const props = defineProps<{
        variant: CtxProductVariant;
        currentRegionId?: string;
        currentZipCode?: string;
    }>();

    const emits = defineEmits<{
        'add-to-cart': [];
        'variant-select': [];
    }>();

    const { t } = useI18n();
    const { formatPrice } = usePriceFormatter();
    const { cart } = useCartQuery();
    const cartAction = useCartAction();

    const productAvailability = computed(() => getAvailabilityForVariantBasedOnCart(props.variant, cart.value, true));
    const productCompletionDate = computed(() => props.variant.depotQuantity.completion_date);
    const productOnDemandCompletionDate = computed(() => props.variant.depotQuantity.on_demand_completion_date);

    const hasPrice = computed(
        () =>
            props.variant.original_price != null &&
            props.variant.original_price !== 0 &&
            props.currentRegionId != null &&
            props.currentZipCode != null
    );

    const priceDisplay = computed(() => {
        return hasPrice.value && props.variant.original_price != null
            ? formatPrice(props.variant.original_price)
            : t('PRODUCT.PRICE_ON_REQUEST');
    });

    const depot = computed(() => props.variant.depotQuantity.depot);

    let isVariantAvailableForCart = computed(() => cartAction.isVariantAddableToCart(props.variant));

    watch(cart, () => {
        isVariantAvailableForCart = computed(() => cartAction.isVariantAddableToCart(props.variant));
    });
</script>
<template>
    <Card class="item">
        <template #title>
            <Button text class="variant-title" :label="variant.title" @click="emits('variant-select')"></Button>
        </template>
        <template #content>
            <div class="content">
                <div class="main-info">
                    <div class="variant-property text-md-regular-line-height-100">
                        <DepotLocationWithIcon :depot-type="depot.type" :depot-location="depot.address.city" />
                    </div>
                    <div class="variant-property text-md-regular-line-height-100 variant-product-availability">
                        <ProductAvailability
                            :product-available="productAvailability"
                            :completion-date="productCompletionDate"
                            :on-demand-completion-date="productOnDemandCompletionDate"
                        />
                    </div>
                </div>
                <div class="other-info">
                    <strong class="text-md-bold-line-height-auto price-display">{{ priceDisplay }}</strong>
                    <IconButtonWithOverlayPanel v-if="hasPrice" class="icon-button-positioned">
                        <template #icon>
                            <span class="pi pi-info-circle info-icon" />
                        </template>

                        <template #overlay-panel-content>
                            <PriceInformation :depot-type="depot.type" :depot-location="depot.address.city" />
                            <span class="price-information">{{ t('PRODUCT.PRICE_EXCLUDING_TAX') }}</span>
                        </template>
                    </IconButtonWithOverlayPanel>
                    <div v-if="variant.id != null && hasPrice">
                        <Button
                            icon="pi pi-cart-plus"
                            :disabled="
                                variant.original_price == null ||
                                variant.original_price === 0 ||
                                productAvailability === Availability.NotAvailable ||
                                !isVariantAvailableForCart
                            "
                            :severity="
                                variant.original_price == null || variant.original_price === 0 ? 'secondary' : 'primary'
                            "
                            @click="emits('add-to-cart')"
                        />
                    </div>
                </div>
            </div>
        </template>
    </Card>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .variant-title {
        padding: 0;

        &:hover {
            background: transparent;
        }
    }

    .icon-button-positioned:hover {
        border: 0 none;
        border-radius: 50%;
        background-color: main.$color-background-bluegray;
    }

    .content {
        display: flex;
        gap: main.$spacing-5;
        align-items: start;
    }

    .main-info {
        flex: 1;
    }

    .variant-property {
        display: flex;
        gap: 1ch;
        align-items: center;
    }

    .variant-product-availability {
        margin-top: main.$spacing-3;
    }

    .other-info {
        display: flex;
        gap: main.$spacing-5;
        align-items: center;
    }

    .price-display {
        text-align: end;
        white-space: pre;
    }

    .price-information {
        text-align: right;
        font-size: main.$font-size-2;
        color: main.$color-secondary;
    }

    .info-icon {
        color: main.$color-primary-500;
    }

    .item :deep(.p-card-title) {
        display: flex;
        font-size: unset;
        font-weight: unset;
    }

    .item :deep(.p-card-body) {
        padding: main.$spacing-4;
    }

    .item :deep(.p-card-content) {
        padding: 0;
    }
</style>
